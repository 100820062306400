import { useKeenSlider } from 'keen-slider/react'

import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

export function useKeenSliderRefs({
  handleSlideChange,
  slidesNum,
  slidesPerView,
  spacing,
  initialSlide,
  sliderDisabled,
  origin,
  loop,
  interactionTitle,
  ...additionalOptions
}) {
  const [sliderRef, instanceRef] = useKeenSlider({
    disabled: Boolean(sliderDisabled),
    initial: initialSlide,
    loop,
    range: {
      align: true,
    },
    mode: 'free-snap',
    slides: {
      origin,
      number: slidesNum,
      spacing,
      perView: slidesPerView
    },
    slideChanged(s) {
      handleSlideChange(s.track.details.rel)
      trackSliderInteraction(s.track.details.rel, 'dragged')
    },
    dragStarted(s) {
      trackSliderInteraction(s.track.details.rel, 'drag-started')
    },
    dragEnded(s) {
      trackSliderInteraction(s.track.details.rel, 'drag-ended')
    },
    ...additionalOptions
  })

  React.useEffect(
    () => {
      instanceRef.current.update()
    },
    [instanceRef]
  )

  function trackSliderInteraction(index, action) {
    trackInteraction({ title: interactionTitle, action, type: 'slided', index })
  }

  return { sliderRef, instanceRef }
}

